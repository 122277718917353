<template>
	<div class="after-sale">
		<div class="goods-item">
			<img :src="goods.goods_image" alt />
			<div class="goods-info">
				<p class="goods-name">{{ goods.goods_name }}</p>
				<div class="goods-spec" v-if="goods.specs_item_value != ''">{{ goods.specs_item_value }}</div>
				<div class="flex-between">
					<div class="goods-price">
						￥<span>{{ goods.goods_price }}</span>
					</div>
					<div class="goods-num">×{{ goods.goods_nums }}</div>
				</div>
			</div>
		</div>
		<div class="mid-box">
			<div class="row">
				<div class="left">售后类型</div>
				<div class="right" @click="isShowPicker = true" v-if="false">{{ columns[nowIndex] }}</div>
				<div class="right">{{ serviceReason }}</div>
			</div>
			<div class="row">
				<div class="left">申请金额</div>
				<div class="right">￥{{ goods.pay_amount }}</div>
			</div>
			<div class="row row1">
				<div class="left">申请说明</div>
				<textarea placeholder="请简述申请退款说明，谢谢~" v-model="serviceRemark"></textarea>
			</div>
		</div>
		<van-field v-model="userPhone" label="联系电话" type="number" placeholder="请输入联系电话" />
		<div class="btn" @click="toApply">提交</div>
		<van-popup v-model="isShowPicker" position="bottom">
			<van-picker title="售后类型" show-toolbar :columns="columns" :default-index="nowIndex" @confirm="onConfirm"
				@cancel="isShowPicker = false" />
		</van-popup>
	</div>
</template>

<script>
	import {
		getGoodsOrderDetails,
		applyGoodsService
	} from "@/http/api";

	export default {
		components: {},
		data() {
			return {
				userToken: "",
				order_id: '',
				details: {},
				goods: {},
				columns: [
					"未发货，仅退款",
					"商家已发货，需退货退款",
					"已收货，需退货退款"
				],
				nowIndex: 0,
				isShowPicker: false,
				serviceRemark: "",
				userPhone: "",
				isPost: false
			};
		},
		mounted() {
			if (this.$route.query.order_id) {
			    this.userToken = this.$LStorage.getItem("userToken").user_token;
			    this.order_id = this.$route.query.order_id
			    this.getGoodsOrderDetails();
			} else {
			    this.$dialog.alert({
			        message: '申请售后页面参数异常,返回上一页看看',
			    }).then(() => {
			        this.$router.go(-1);
			    });
			}
		},
		methods: {
			// 获取订单详情
			async getGoodsOrderDetails() {
				const res = await getGoodsOrderDetails({
					data: {
						user_token: this.userToken,
						order_id: this.order_id
					}
				});
				this.goods = res.data;
				this.userPhone = res.data.address_phone;
			},
			// 申请售后
			async toApply() {
				if (this.isPost) {
					this.$toast("请勿重复提交");
				} else {
					this.isPost = true;
					const res = await applyGoodsService({
						data: {
							user_token: this.userToken,
							order_id: this.order_id,
							user_phone: this.userPhone,
							service_reason: this.serviceReason,
							service_remark: this.serviceRemark,
							apply_amount: this.goods.pay_amount
						}
					});
					this.isPost = false;
					if (res.code == 200) {
						let that = this;
						this.$toast.success({message:'申请成功', onClose:function(){
							that.$router.push('/AfterSaleOrder');
						}});
					} else {
						this.$toast(res.msgs);
					}
				}
			},
			//选择器确定
			onConfirm(value, index) {
				this.nowIndex = index;
				this.isShowPicker = false;
			}
		},
		computed: {
			serviceReason() {
				let status = this.goods.order_status;
				switch (status) {
					case 2:
						return "未发货，仅退款";
					case 3:
						return "商家已发货，需退货退款";
				}
			}
		},
		filters: {}
	};
</script>

<style lang="scss" scoped>
	.after-sale {
		background: #f6f6f6;
		box-sizing: border-box;
		min-height: 100vh;

		.van-cell {
			line-height: 1.12rem;
			padding: 0 0.24rem;
			font-size: 0.28rem;
		}

		* {
			box-sizing: border-box;
		}

		.goods-item {
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.2rem 3vw;
			border-radius: 0.1rem;
			margin-bottom: 10px;

			img {
				width: 24vw;
				height: 24vw;
				border-radius: 5px;
				object-fit: cover;
			}

			.goods-info {
				width: 64vw;
				height: 24vw;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.goods-name {
					font-size: 0.28rem;
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.goods-spec {
					background: #f5f5f5;
					border-radius: 5px;
					padding: 0.1rem 0.15rem;
					font-size: 0.24rem;
					color: #848484;
					// width: max-content;
				}

				.goods-num {
					font-size: 0.24rem;
				}

				.goods-price {
					font-size: 0.24rem;
					color: #da1313;

					span {
						font-size: 0.2rem;
						font-weight: bold;
					}
				}
			}
		}

		.mid-box {
			width: 100vw;
			background: #fff;
			padding: 0 3vw;
			margin-bottom: 0.3rem;

			.row {
				display: flex;
				align-items: center;
				min-height: 1.05rem;
				border-bottom: 1px solid #eee;
				font-size: 0.28rem;

				.left {
					margin-right: 8vw;
				}
			}

			.row1 {
				flex-wrap: wrap;
				padding-top: 0.35rem;
				border-bottom: none;
				padding-bottom: 0.3rem;

				.left {
					margin-bottom: 0.2rem;
				}

				textarea {
					width: 100%;
					height: 1.5rem;
					background: #f7f7f7;
					padding: 0.2rem 0.28rem;
					font-size: 0.26rem;
				}
			}
		}

		.contact-row {
			background: #fff;
			height: 0.9rem;
			display: flex;
			align-items: center;
			padding: 0 3vw;

			.left {
				font-size: 0.28rem;
				margin-right: 8vw;
			}

			.right {
				color: #7c7c7c;
				font-size: 0.28rem;
			}
		}

		.btn {
			width: 90vw;
			margin: 1rem 5vw 0;
			height: 0.9rem;
			border-radius: 0.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			background: #e50012;
			font-size: 0.3rem;
			font-weight: bold;
		}
	}
</style>
